import React, { useState } from 'react';
import axios from 'axios';

const CreateForumPost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = { title, content };

    axios.post('http:8081/api/forum/create', postData)
      .then(response => {
        console.log('Postagem criada', response.data);
        // Atualize o estado ou redirecione
      })
      .catch(error => {
        console.error('Erro ao criar postagem', error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input 
        type="text" 
        placeholder="Título" 
        value={title} 
        onChange={(e) => setTitle(e.target.value)} 
      />
      <textarea 
        placeholder="Conteúdo" 
        value={content} 
        onChange={(e) => setContent(e.target.value)} 
      />
      <button type="submit">Criar Tópico</button>
    </form>
  );
};

export default CreateForumPost;
