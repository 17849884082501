import React from 'react';
import styles from '../Pages_styles/Login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://liberumscientia.shop/auth/home', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Armazena o token JWT
        console.log('Login bem-sucedido:', data);
        setRedirect(true); // Define o estado para redirecionar
      } else {
        setError('Login ou senha incorretos');
      }
    } catch (err) {
      setError('Ocorreu um erro ao tentar fazer login');
    }
  };

  useEffect(() => {
    if (redirect) {
      console.log('funcionou');
      navigate('/home'); // Use o caminho correto para a página que deseja redirecionar
    }
  }, [redirect, navigate]);

  return (
    <div className={styles.main}>
              <Link to='/tryfindme' className={styles.register}>Register</Link>
      <div className={styles.login_container}>
        <form onSubmit={handleSubmit} className={styles.login_form}>
          <h2>Login</h2>
          <div className={styles.form_group}>
            <label htmlFor="email">Login:</label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.form_group}>
            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className={styles.button}>Entrar</button>
          {error && <p className={styles.error}>{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default Login;
