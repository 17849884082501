import React from 'react';
import logo from '../logoLS.jpeg';
import styles from '../Styles/Header.module.css'
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className={styles.Header}>
            <img src={logo} alt="logo" width="80px" height="80px" />
            <h1>Liberum Scientia</h1>
            <ul>
                <li><Link to='/home'>Início</Link></li>
                <li>Contato</li>
                <li><Link to="/forum">Forum</Link></li>
                <li>Notícias</li>
            </ul>
        </div>
    );
}

export default Header;
