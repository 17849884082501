import React from "react";
import Header from "../Components/Header";
import styles from '../Pages_styles/Forum.module.css';
import CreateForumPost from "../Components/CreateForumPost";
import RequestForumPosts from "../Components/RequestForumPosts";


function Forum () {
    return (
        <div className={styles.main}>
            <Header />
            <h1 className={styles.header2}>Bem vindo ao Forum Liberum Scientia!</h1>
            <div className={styles.criarpost}>
                <CreateForumPost />
            </div>
            <div className={styles.posts}>
                <RequestForumPosts />
            </div>
        </div>
    )
}

export default Forum;