import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, []); // Certifique-se de que o hook é executado uma vez ao montar

  if (token === null) {
    // Pode mostrar um loading enquanto verifica o token
    return <div>Loading...</div>;
  }

  if (!token) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
