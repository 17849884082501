import React, { useState } from 'react';

function Register() {
  // Estados para armazenar o email, a senha e o feedback do usuário
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  // Função para lidar com o envio do formulário
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (email && password) {
      try {
        // Corrigindo a URL da requisição
        const response = await fetch('https://liberumscientia.shop/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
  
        if (response.ok) {
          setMessage('Conta criada com sucesso!');
        } else {
          const data = await response.json();
          setMessage(`Erro: ${data.message || 'Erro ao criar conta.'}`);
        }
      } catch (error) {
        setMessage('Erro na comunicação com o servidor.');
      }
    } else {
      setMessage('Preencha todos os campos!');
    }
  };

  return (
    <div>
      <h2>Registrar Conta</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu email"
            required
          />
        </div>
        <div>
          <label>Senha:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Digite sua senha"
            required
          />
        </div>
        <button type="submit">Registrar</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default Register;
