import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import styles from "../Pages_styles/Home.module.css";

function Home() {
    return (
        <div className={styles.main}>
        <Header />
        <h1>Bem vindo ao Liberum Scientia!</h1>
        <p>Este é um projeto de fins gerais sem escopo definido. As coisas por aqui podem não funcionar muito bem, então boa sorte!</p>
        </div>
    )
}

export default Home;