import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AuthProvider } from './Protected/AuthContext';
import { useEffect } from 'react';
import { useState } from 'react';
import ProtectedRoute from './Protected/ProtectedRoute';
import './App.css';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Register from './Pages/Register';
import Forum from './Pages/Forum';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Login />} /> 
          <Route path='/tryfindme' element={<Register />} />

          <Route
          path='/home'
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
          />

          <Route
          path='/forum'
          element={
            <ProtectedRoute>
              <Forum />
            </ProtectedRoute>
          }
          />

        </Routes>
      </Router>
    </AuthProvider>

  );
}

export default App;
