import { useState, useEffect } from 'react';

const RequestForumPosts = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetch(`http://8081/api/forum/posts?page=${page}&size=10`)
      .then(response => response.json())
      .then(data => {
        setPosts(data.content);
        setTotalPages(data.totalPages);
      });
  }, [page]);

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <div>
      <ul>
        {posts.map(post => (
          <li key={post.id}>{post.title}</li>
        ))}
      </ul>
      <button onClick={handlePreviousPage} disabled={page === 0}>Previous</button>
      <button onClick={handleNextPage} disabled={page === totalPages - 1}>Next</button>
    </div>
  );
};

export default RequestForumPosts;
